<template>

  <div>

    <script-group-sidebar-form
      :is-script-group-form-sidebar-active.sync="isScriptGroupFormSidebarActive"
      :script-group-data.sync="dataModel"
      :reset-form-callback="resetForm"
      @refetch-data="fetchScriptGroups"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            v-if="false"
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col>
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-if="false"
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                variant="primary"
                @click="prepareAdd"
              >
                <span class="text-nowrap">{{ $t('script.group.btn.add') }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refScriptGroupListTable"
        primary-key="id"
        responsive
        :items="scriptGroups"
        :fields="tableColumns"
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
        show-empty
        :empty-text="loading ? $t('shared.loading') : $t('shared.no-data')"
      >

        <!-- Column: Name -->
        <template #cell(name)="data">
          <b-link @click="prepareUpdate(data.item)">
            {{ data.item.name }}
          </b-link>
        </template>

        <!-- Column: scriptsCount -->
        <template #cell(scriptsCount)="data">
          <b-badge :variant="data.item.scriptsCount > 0 ? 'light-secondary' : 'light-danger'">
            {{ data.item.scriptsCount }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-button
            variant="flat-primary"
            @click="prepareUpdate(data.item)"
          >
            <feather-icon icon="EditIcon" />
          </b-button>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ $t('shared.pagination-summary', dataMeta) }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-if="totalItems > perPage"
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BPagination, BLink, BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useScriptGroup from './useScriptGroup'
import { cloneNested } from '@/helpers/helpers'
import store from '@/store'
import ScriptGroupSidebarForm from '@/views/organization/script/groups/ScriptGroupSidebarForm'

export default {
  components: {
    ScriptGroupSidebarForm,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BLink,
    BTable,
    BPagination,
    BBadge,

    vSelect,
  },
  data() {
    return {
      // Table Handlers
      tableColumns: [
        { key: 'name', label: this.$t('script.group.label.name'), sortable: true },
        { key: 'coursebook', label: this.$t('script.group.label.coursebook'), sortable: true },
        { key: 'scriptsCount', label: this.$t('script.group.label.scripts-count'), sortable: true },
        { key: 'actions', label: this.$t('shared.actions'), sortable: false },
      ],
    }
  },

  setup() {
    const isScriptGroupFormSidebarActive = ref(false)
    const dataModel = ref(cloneNested(store.state.scriptGroup.scriptGroupModel))

    const resetForm = () => {
      dataModel.value = cloneNested(store.state.scriptGroup.scriptGroupModel)
    }

    const prepareAdd = () => {
      isScriptGroupFormSidebarActive.value = true
    }

    const prepareUpdate = dataModelForUpdate => {
      dataModel.value = cloneNested(dataModelForUpdate)
      isScriptGroupFormSidebarActive.value = true
    }

    const {
      loading,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refScriptGroupListTable,
      fetchScriptGroups,
      scriptGroups,
    } = useScriptGroup()

    return {
      loading,
      dataModel,
      resetForm,

      // Sidebar
      isScriptGroupFormSidebarActive,
      prepareAdd,
      prepareUpdate,

      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refScriptGroupListTable,
      fetchScriptGroups,
      scriptGroups,

      // Filter
      avatarText,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
