<template>
  <b-sidebar
    id="script-group-sidebar-form"
    :visible="isScriptGroupFormSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetLocalScriptGroupData"
    @change="(val) => $emit('update:is-script-group-form-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ localScriptGroupData.id ? $t('script.group.form.title-update') : $t('script.group.form.title-add') }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <rc-overlay
        :show="processing"
        no-fade
        variant="white"
      >

        <!-- BODY -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >

            <!-- Full Name -->
            <validation-provider
              #default="validationContext"
              :name="$t('script.group.label.name')"
              rules="required"
            >
              <b-form-group
                :label="$t('script.group.label.name')"
                label-for="full-name"
              >
                <b-form-input
                  id="full-name"
                  v-model="localScriptGroupData.name"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Coursebook -->
            <validation-provider
              #default="validationContext"
              :name="$t('script.group.label.coursebook')"
              rules=""
            >
              <b-form-group
                :label="$t('script.group.label.coursebook')"
                label-for="username"
              >
                <b-form-input
                  id="coursebook"
                  v-model="localScriptGroupData.coursebook"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ localScriptGroupData.id ? $t('shared.btn.update') : $t('shared.btn.add') }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                {{ $t('shared.btn.cancel') }}
              </b-button>
            </div>

          </b-form>
        </validation-observer>

        <div
          v-if="localScriptGroupData.scripts && localScriptGroupData.scripts.length"
          class="mx-2"
        >
          <h4>{{ $t('script.group.form.scripts-list') }}</h4>
          <!-- draggable -->
          <draggable
            v-model="localScriptGroupData.scripts"
            tag="ul"
            class="list-group list-group-flush cursor-move"
            @end="onSort"
          >
            <b-list-group-item
              v-for="(script, index) in localScriptGroupData.scripts"
              :key="index"
              tag="li"
            >
              <div
                class="float-right"
              >
                <b-button
                  :to="{name: 'organization-script-edit', params: {id: script.id}}"
                  size="sm"
                  variant="flat-primary"
                  class="ml-1 my-n1"
                >
                  <feather-icon
                    icon="EditIcon"
                  />
                </b-button>
              </div>
              {{ index + 1 }}. {{ script.subject }}
            </b-list-group-item>
          </draggable>
        </div>
      </rc-overlay>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BListGroupItem,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import { ref, watch } from '@vue/composition-api'
import draggable from 'vuedraggable'
import store from '@/store'
import { cloneNested, parseRequestError } from '@/helpers/helpers'
import i18n from '@/libs/i18n'
import RcOverlay from '@/layouts/components/rc/RcOverlay'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BListGroupItem,

    RcOverlay,
    draggable,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isScriptGroupFormSidebarActive',
    event: 'update:is-script-group-form-sidebar-active',
  },
  props: {
    isScriptGroupFormSidebarActive: {
      type: Boolean,
      required: true,
    },
    resetFormCallback: {
      type: Function,
      required: true,
    },
    scriptGroupData: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },
  setup(props, { emit }) {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(props.resetFormCallback)

    const processing = ref(false)
    const localScriptGroupData = ref(cloneNested(props.scriptGroupData))

    const resetLocalScriptGroupData = () => {
      resetForm()
      localScriptGroupData.value = cloneNested(props.scriptGroupData)
    }

    watch(() => props.scriptGroupData, scriptGroupData => {
      if (scriptGroupData.id) {
        processing.value = true
        store.dispatch('scriptGroup/fetchScriptGroup', scriptGroupData.id)
          .then(res => {
            localScriptGroupData.value = cloneNested(res.data)
          })
          .catch(err => parseRequestError(err))
          .finally(() => {
            processing.value = false
          })
      } else {
        localScriptGroupData.value = cloneNested(scriptGroupData)
      }
    })

    const dispatchForm = () => {
      if (localScriptGroupData.value.id) {
        return store.dispatch(
          'scriptGroup/patchScriptGroup',
          { id: localScriptGroupData.value.id, data: localScriptGroupData.value },
        )
      }
      return store.dispatch('scriptGroup/addScriptGroup', localScriptGroupData.value)
    }

    const onSubmit = () => {
      processing.value = true
      dispatchForm()
        .then(() => {
          if (localScriptGroupData.value.id) {
            window.toast.notify.success(i18n.t('script.group.toast.updated'))
          } else {
            window.toast.notify.success(i18n.t('script.group.toast.added'))
          }

          emit('refetch-data')
          emit('update:is-script-group-form-sidebar-active', false)
        })
        .catch(res => {
          window.toast.notify.danger(i18n.t('script.group.toast.fail'), res.message)
        })
        .finally(() => {
          processing.value = false
        })
    }

    const onSort = () => {
      store.dispatch(
        'script/updateScriptsOrder',
        localScriptGroupData.value.scripts,
      )
        .catch(res => {
          window.toast.notify.danger(i18n.t('script.group.toast.fail'), res.message)
        })
    }

    return {
      processing,
      localScriptGroupData,
      onSubmit,
      onSort,
      refFormObserver,
      getValidationState,
      resetForm,
      resetLocalScriptGroupData,
    }
  },
}
</script>
